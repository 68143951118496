<template>
    <div>
        <div class="ml-3 mr-3 mb-3 fl-te-c">
            <h4 class="mb-3">Return Details</h4>
            <div class="btn-group">
                <btn size="sm" @click="$router.push('/warehouse/return/list/')" text="Back"/>
            </div>
        </div>
        <loading-animation v-if="DataLoading"/>
        <purchase-details v-if="details" class="ml-3 mr-3 mb-5" :data="details"/>
        <div class="round-2 pb-6 text-center" v-if="noData">
            <img style="height: 12rem;" alt="No Data Available" src="../../../assets/img/no-data.png">
            <p>No data for purchase ID found</p>
        </div>
    </div>
</template>

<script>
// import urls from '../../../data/urls';
import PurchaseDetails from './Components/PurchaseDetails';
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name       : 'AddReturnComponent',
    components : { PurchaseDetails },
    data () {
        return {
            DataLoading : false,
            noData      : false,
            id          : this.$route.params.id,
            details     : {}
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        noDataAvailable () {
            this.noData = true;
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.warehouseAdmin.purchaseReturn.details, { entry_id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.details = json;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
