<template>
    <div>
        <div v-if="entry.id" class="card bs-4 p-0 pb-3">
            <div class="fl-te-c p-3 bb-1">
                <h5>Purchase Info</h5>
                <div class="btn-group">
                    <btn v-if="return_entry.status==='Created'" size="sm" loading-text="Submitting.." :loading="loading"
                         @click="submitClicked" text="Submit"/>
                </div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Purchase ID</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ entry.id }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Vendor</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ entry.vendor }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ entry.date }}</div>
                <div class="col-1"/>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ entry.status }}</div>
                <div class="col-1"/>
                <div v-if="entry.remarks" class="col-1 label">Remarks</div>
                <div v-if="entry.remarks" class="col-2 p-1 b-1 text-muted round-1">{{ entry.remarks }}</div>
                <div v-if="entry.remarks" class="col-1"/>
            </div>
            <custom-simple-table v-if="entry" class="table-borderless mt-4 mb-0" ref="table" title="Items"
                                 :fields="fields" :data="entry.items">
                <template #no="rowData">
                    <span>{{ getSlNo(rowData.rowData, entry.items) }}</span>
                </template>
                <template #actions="{rowData}">
                    <div class="btn-group-tight">
                        <btn v-if="!rowData.has_returned" @click="showReturnModal(rowData)" size="xs"
                             text="Return Item"/>
                        <span v-else>No actions to perform</span>
                    </div>
                </template>
            </custom-simple-table>
            <modal no-close-on-backdrop title="Return Item" ref="returnItemModal" width="30r">
                <b-form :save-url="returnItemUrl" ref="editFormModal" v-slot="{model, loading}" @success="formSuccess"
                        :save-params="{id : item_id, entry_id : entry.id}">
                    <validated-input placeholder="Quantity" v-model="model.quantity" name="quantity" :disabled="loading"
                                     :rules="{ required : true}"/>
                    <textarea-input name="Reason" v-model="model.reason" placeholder="Reason"
                                    :disabled="loading" :rules="{required: true}"/>
                    <btn size="xs" text="Submit" :loading="loading" loading-text="Submitting..."></btn>
                </b-form>
            </modal>
        </div>
        <div v-if="return_entry.return_id" class="card bs-4 p-0 pb-3">
            <h5 class="p-3 bb-1">Return Items</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Return ID</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ return_entry.return_id }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ return_entry.date }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ return_entry.status }}</div>
                <div class="col-1"/>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Vendor</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ return_entry.vendor }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Created By</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ return_entry.approved_by }}</div>
                <div class="col-1"/>
            </div>
            <custom-simple-table v-if="return_entry.items.length!==0" class="table-borderless p-0 mt-4" ref="table"
                                 title="Items"
                                 :fields="fields1" :data="return_entry.items">
                <template #no="rowData">
                    <span>{{ getSlNo(rowData.rowData, return_entry.items) }}</span>
                </template>
                <template #actions="{rowData}">
                    <div class="btn-group-tight">
                        <btn v-if="return_entry.status==='Created'" @click="removeItem(rowData)" size="xs"
                             text="Remove Item"/>
                        <span v-else>{{ return_entry.status }}</span>
                    </div>
                </template>
            </custom-simple-table>
            <delete-modal ref="deleteModal" :url="deleteItemUrl" :params="deletingItem" @response="formSuccess">
                <p>You are about to delete the item. Are you sure ?</p>
                <template #loading>
                    <delete-animation/>
                    Please wait while we delete.
                </template>
            </delete-modal>
        </div>
    </div>
</template>

<script>

import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name  : 'PurchaseDetails',
    props : {
        data : { type : Object }
    },
    data () {
        return {
            DataLoading  : true,
            loading      : false,
            return_entry : {},
            entry        : {},
            item_id      : '',
            deletingItem : {
                id       : '',
                entry_id : ''
            },
            returnItemUrl : urls.warehouseAdmin.purchaseReturn.returnEntryItem,
            deleteItemUrl : urls.warehouseAdmin.purchaseReturn.deleteEntryItem,
            fields        : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Items'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'tax',
                    sortField : 'tax',
                    title     : 'Tax'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total'
                },
                {
                    name      : '__slot:actions',
                    sortField : '',
                    title     : 'Actions'
                }
            ],
            fields1 : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Items'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'tax',
                    sortField : 'tax',
                    title     : 'Tax'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total'
                },
                {
                    name      : 'reason',
                    sortField : 'reason',
                    title     : 'Reason'
                },
                {
                    name      : '__slot:actions',
                    sortField : '',
                    title     : 'Actions'
                }
            ]
        };
    },
    watch : {
        data : {
            handler () {
                this.setData();
            }
        }
    },
    methods : {
        getSlNo (rowData, items) {
            for (let i = 0; i <= items.length; i++) {
                if (items[i].id === rowData.id) {
                    return i + 1;
                }
            }
        },
        setData () {
            this.details = this.data;
            this.entry = this.data.purchase_entry;
            this.return_entry = this.data.return_items;
            this.DataLoading = false;
        },
        removeItem (rowData) {
            this.deletingItem.id = rowData.id;
            this.deletingItem.entry_id = this.entry.id;
            this.$refs.deleteModal.show();
        },
        showReturnModal (item) {
            this.$refs.returnItemModal.show();
            this.item_id = item.id;
        },
        formSuccess (response) {
            this.$refs.returnItemModal.close();
            this.entry = response.data['return-details'].purchase_entry;
            this.return_entry = response.data['return-details'].return_items;
            this.deletingItem.id = '';
        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.warehouseAdmin.purchaseReturn.submit, {
                id : that.return_entry.return_id
            });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Return Requested', 'Success', {
                    type : 'success'
                });
                that.$router.push('/warehouse/return/list/');
                that.loading = false;
            } else {
                that.$notify('Unable to request for return.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
            that.loading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
